import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(SplitText, ScrollTrigger);

export default function animations () {
    // GSAP ANIMATION (thank you Chai!!!!)

    let bannerButtons = document.querySelectorAll('.home__hero__btn');
    bannerButtons.forEach(item => {
        item.addEventListener('click', function () {
            setTimeout(() => {
                splitTextAnimation();
            }, '450');
        });
    });

    const splitTextAnimation = () => {
        // regular text
        gsap.utils.toArray('.animate-text').forEach(text => {
            const textChild = new SplitText(text, {
                type: 'lines',
                linesClass: 'animate-child',
            });
            const textWrapper = new SplitText(text, {
                type: 'lines',
                linesClass: 'animate-wrapper',
            });

            const textAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: text,
                    start: 'top 90%',
                    end: 'bottom 0%',
                    lazy: false,
                    onEnterBack: self => {
                        // don't animate on scroll up (for pausing)
                        self.animation.pause(100);
                        self.disable();
                    },
                },
                onComplete: () => {
                    // textWrapper.revert();
                    // textChild.revert();
                },
                onStart: () => {
                    text.classList.add('loaded');
                },
            });

            textAnimation.from(textChild.lines, {
                duration: 1,
                opacity: 0,
                y: 50,
                ease: 'power4',
                stagger: 0.25,
                rotation: 0.1,
            });
        });

        // large text
        gsap.utils.toArray('.animate-text-lg').forEach(lgText => {
            const lgTextChild = new SplitText(lgText, {
                type: 'lines',
                linesClass: 'animate-child-lg',
            });
            const lgTextWrapper = new SplitText(lgText, {
                type: 'lines',
                linesClass: 'animate-wrapper-lg',
            });

            const lgTextAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: lgText,
                    start: 'top 80%',
                    end: 'bottom 0%',
                    lazy: false,
                    onEnterBack: self => {
                        // don't animate on scroll up (for pausing)
                        self.animation.pause(100);
                        self.disable();
                    },
                },
                onComplete: () => {
                    // lgTextWrapper.revert();
                    // lgTextChild.revert();
                },
                onStart: () => {
                    lgText.classList.add('loaded');
                },
            });

            lgTextAnimation.from(lgTextChild.lines, {
                duration: 1.5,
                opacity: 0,
                y: 100,
                ease: 'power4',
                stagger: 0.25,
                delay: 0.2,
                rotation: 0.1,
            });
        });

        // large text delay
        gsap.utils.toArray('.animate-text-lg-delay').forEach(lgDelayText => {
            const lgDelayTextChild = new SplitText(lgDelayText, {
                type: 'lines',
                linesClass: 'animate-child-lg-delay',
            });
            const lgDelayTextWrapper = new SplitText(lgDelayText, {
                type: 'lines',
                linesClass: 'animate-wrapper-lg-delay',
            });

            const lgDelayTextAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: lgDelayText,
                    start: 'top 80%',
                    end: 'bottom 0%',
                    lazy: false,
                    onEnterBack: self => {
                        // don't animate on scroll up (for pausing)
                        self.animation.pause(100);
                        self.disable();
                    },
                },
                onComplete: () => {
                    // lgDelayTextWrapper.revert();
                    // lgDelayTextChild.revert();
                },
                onStart: () => {
                    lgDelayText.classList.add('loaded');
                },
            });

            lgDelayTextAnimation.from(lgDelayTextChild.lines, {
                duration: 1.5,
                opacity: 0,
                y: 100,
                ease: 'power4',
                stagger: 0.25,
                delay: 0.8,
                rotation: 0.1,
            });
        });
    };

    const fullWidthImgs = () => {
        const eachImg = document.querySelectorAll('.full-width-img');

        eachImg.forEach(item => {
            let left = item.querySelector('.left');
            let right = item.querySelector('.right');
            let img = item.querySelector('picture');

            gsap.to(left, {
                scrollTrigger: {
                    trigger: item,
                    start: 'top center',
                    end: 'middle top',
                    scrub: 0.8,
                    // markers: true,
                },
                scaleX: 0,
            });

            gsap.to(right, {
                scrollTrigger: {
                    trigger: item,
                    start: 'top center',
                    end: 'middle top',
                    scrub: 0.8,
                    // markers: true,
                },
                scaleX: 0,
            });

            gsap.to(img, {
                scrollTrigger: {
                    trigger: '.full-width-img',
                    start: 'middle bottom',
                    scrub: 0.8,
                    // markers: true,
                },
                scale: 1.05,
                y: -5,
            });
        });
    };

    

    /* SCROLL TRIGGER ANIMATIONS */
    let sections = Array.from(
        document.getElementsByClassName('landing__types__img')
    );

    let options = {
        threshold: 0.9,
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                fullWidthImgs();
            } else if (!entry.isIntersecting) {
                observer.unobserve(entry)
            }
        });
    }, options);

    sections.forEach(section => {
        observer.observe(section);
    });

    const floatUp = () => {
        const eachFloater = document.querySelectorAll('.float');

        eachFloater.forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: 'middle bottom',
                    toggleActions: 'play none resume none',
                },
                opacity: 1,
                y: 0,
                ease: 'power4',
                duration: 2,
            });
        });
    };

    function ready (callback) {
        // in case the document is already rendered
        if (document.readyState != 'loading') callback();
        // modern browsers
        else if (document.addEventListener)
            document.addEventListener('DOMContentLoaded', callback);
        // IE <= 8
        else
            document.attachEvent('onreadystatechange', function () {
                if (document.readyState == 'complete') callback();
            });
    }

    ready(function () {
        // do something
        splitTextAnimation();
        fullWidthImgs();
        floatUp();
    });
}
