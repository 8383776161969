/** import external dependencies */
import $ from 'jquery/dist/jquery.js';
import "waypoints/lib/jquery.waypoints.min";

/** import local dependencies */
import Router from './util/Router';
import common from './routes/common';
import blog from './routes/blog';
import home from './routes/home';
import pageTemplateTemplateAbout from './routes/about';
import pageTemplateTemplateServicesProducts from './routes/services-products';
import singleServiceProduct from './routes/single-service-product';
import pageTemplateTemplateContact from './routes/contact';
import pageTemplateTemplateGenContent from './routes/gen-content';
import pageTemplateTemplateLanding from './routes/landing';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    home,
    pageTemplateTemplateAbout,
    pageTemplateTemplateServicesProducts,
    singleServiceProduct,
    pageTemplateTemplateGenContent,
    pageTemplateTemplateLanding,
    pageTemplateTemplateContact
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
