import Swiper from "swiper/dist/js/swiper";
import easydropdown from 'easydropdown';

export default {
    init() {

        // menu check
        const menuCheck = (menuLinks) => {
            if (window.innerWidth < 1024) {
                menuLinks.forEach(link => {
                    link.setAttribute("tabindex", "-1");
                });
            } else {
                menuLinks.forEach(link => {
                    link.setAttribute("tabindex", "0");
                });
            }
        }


        // trap focus in menu
        const trapFocus = (element, menuOpen, hamburgerButton) => {
            let focusableEls = element.querySelectorAll("a[href]:not([disabled]), button:not([disabled])");
            const firstFocusableEl = hamburgerButton;
            const lastFocusableEl = focusableEls[focusableEls.length - 1];
            const KEYCODE_TAB = 9;
        
            element.addEventListener("keydown", function(e) {
                if (menuOpen === true) {
                    let isTabPressed = (e.key === "Tab" || e.keyCode === KEYCODE_TAB);
                    if (!isTabPressed) return;

                    /* shift + tab */
                    if (e.shiftKey && document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    /* tab */
                    } else if (document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                }
            });
        }


        // hamburger
        const hamburger = () => {
            let menuOpen = false;
            const html = document.querySelector("html");
            const body = document.querySelector("body");
            const hamburgerButton = document.querySelector(".hamburger");
            const menu = document.getElementById("primary-nav");
            const logo = document.querySelector("header .logo");
            const btns = document.querySelector("header .search-btn");
            const mainCnt = document.querySelector("main");
            const footerCnt = document.querySelector("footer");
            let menuLinks = menu.querySelectorAll("a[href]:not([disabled]), button:not([disabled])");
            
            menuCheck(menuLinks);

            window.addEventListener("resize", () => menuCheck(menuLinks));

            hamburgerButton.addEventListener("click", () => {
                hamburgerButton.classList.toggle("active");
                menu.classList.toggle("show");
                body.classList.toggle("no-scroll");
                html.classList.toggle("no-scroll");

                if (hamburgerButton.classList.contains("active")) {
                    btns.style.display = "none";
                    menu.setAttribute("aria-expanded", "true");
                    mainCnt.setAttribute("aria-hidden", "true");
                    footerCnt.setAttribute("aria-hidden", "true");

                    menuOpen = true;
                    trapFocus(menu, menuOpen, hamburgerButton);
                    menuLinks.forEach(link => {
                        link.setAttribute("tabindex", "0");
                    });
                    logo.setAttribute("tabindex", "-1");

                } else {
                    btns.style.display = "flex";
                    menu.setAttribute("aria-expanded", "false");
                    mainCnt.setAttribute("aria-hidden", "false");
                    footerCnt.setAttribute("aria-hidden", "false");
                    menuOpen = false;
                    menuLinks.forEach(link => {
                        link.setAttribute("tabindex", "-1");
                    });
                    logo.setAttribute("tabindex", "0");
                }
            });
        };

        hamburger();


                /* TOPNAV ACCESSIBILITY */
        const nav_menu_items = document.querySelectorAll('.topnav > ul > li');
        // console.log(nav_menu_items)
        for (let i = 0; i < nav_menu_items.length; i++) {
            const submenu_button = nav_menu_items[i].querySelector('.top-level-button');
            const submenu = nav_menu_items[i].querySelector('.sub-menu');
            nav_menu_items[i].addEventListener('mouseenter', function () {
                // console.log(nav_menu_items[i])
                if (window.innerWidth > 1024) {
                    if (submenu_button !== null) {
                        submenu_button.setAttribute('aria-expanded', true);
                    }
                    if (submenu !== null) {
                        submenu.setAttribute('aria-hidden', false);
                    }
                }
            });

            nav_menu_items[i].addEventListener('mouseleave', function () {
                if (window.innerWidth > 1024) {
                    if (submenu_button !== null) {
                        submenu_button.setAttribute('aria-expanded', false);
                    }
                    if (submenu !== null) {
                        submenu.setAttribute('aria-hidden', true);
                    }
                }
            });
        }

        const nav_menu_links = document.querySelectorAll('.topnav > ul > li > a, .topnav > ul > li > .top-level-button');
        if (nav_menu_links !== null) {
            for (let nml_index = 0; nml_index < nav_menu_links.length; nml_index++) {
                nav_menu_links[nml_index].addEventListener('focus', function () {
                    this.parentElement.classList.add('focus');
                });

                nav_menu_links[nml_index].addEventListener('blur', function () {
                    const link_parent = this.parentElement;
                    setTimeout(function () {
                        const target = document.activeElement;
                        const target_parent = target.parentElement;
                        if (!target_parent.parentElement.classList.contains('sub-menu') && link_parent !== target_parent) {
                            link_parent.classList.remove('focus');
                        }
                    }, 100);
                })
            }
        }

        const submenu_buttons = document.querySelectorAll('.topnav > ul > li > .top-level-button');

        if (submenu_buttons !== null) {
            for (let sb_index = 0; sb_index < submenu_buttons.length; sb_index++) {
                submenu_buttons[sb_index].addEventListener('click', function () {
                    // Remove 'active' class from all buttons
                    submenu_buttons.forEach(button => {
                        if (button !== this) {
                            button.classList.remove('active');
                            let otherSubmenu = button.parentElement.querySelector('.sub-menu');
                            otherSubmenu.classList.remove('show');
                            otherSubmenu.setAttribute('aria-hidden', true);
                            button.setAttribute('aria-expanded', false);
                        }
                    });
        
                    // Toggle 'active' class for the clicked button
                    this.classList.toggle('active');
        
                    let submenu = this.parentElement.querySelector('.sub-menu');
                    if (this.classList.contains('active')) {
                        this.setAttribute('aria-expanded', true);
                        submenu.classList.add('show');
                        submenu.setAttribute('aria-hidden', false);
                    } else {
                        this.setAttribute('aria-expanded', false);
                        submenu.classList.remove('show');
                        submenu.setAttribute('aria-hidden', true);
                    }
                })
            }
        }
        

        const submenu_links = document.querySelectorAll('.topnav > ul > li > .sub-menu > li > a');
        if (submenu_links !== null) {
            for (let sbl_index = 0; sbl_index < submenu_links.length; sbl_index++) {
                if(submenu_links[sbl_index].dataset.text === undefined) {
                    submenu_links[sbl_index].dataset.text = submenu_links[sbl_index].innerHTML;
                }
                submenu_links[sbl_index].addEventListener('blur', function () {
                    const link_parent = this.parentElement.parentElement;
                    setTimeout(function () {
                        let target = document.activeElement;
                        let target_parent = target.parentElement.parentElement;
                        if (link_parent !== target_parent) {
                            link_parent.classList.remove('show');
                            link_parent.setAttribute('aria-hidden', true);
                            link_parent.parentElement.querySelector('.top-level-button').classList.remove('active');
                            link_parent.parentElement.querySelector('.top-level-button').setAttribute('aria-expanded', false);
                            link_parent.parentElement.classList.remove('focus');
                        }
                    }, 100);
                })
            }
        }

        const submenu_items = document.querySelectorAll('.topnav .menu-item-has-children');
        if(submenu_items !== null) {
            for (let si_index = 0; si_index < submenu_items.length; si_index++) {
                submenu_items[si_index].addEventListener('click', function (e) {
                    if(e.target.tagName === 'LI') {
                        const submenu_button = this.querySelector('.top-level-button');
                        submenu_button.classList.toggle('active');
                        let submenu = submenu_button.parentElement.querySelector('.sub-menu');
                        if(submenu === null) {
                            submenu = submenu_button.parentElement.parentElement.querySelector('.sub-menu');
                        }
                        if (submenu_button.classList.contains('active')) {
                            submenu_button.setAttribute('aria-expanded', true);
                            submenu.classList.add('show');
                            submenu.setAttribute('aria-hidden', false);
                        } else {
                            submenu_button.setAttribute('aria-expanded', false);
                            submenu.classList.remove('show');
                            submenu.setAttribute('aria-hidden', true);
                        }
                    }
                })
            }
        }


        // mobile menu set
        const mobileMenuSet = () => {
            const header = document.querySelector(".header__inner");
            const headerHeight = header.clientHeight;
            const mainMenu = document.querySelector(".topnav");
            mainMenu.style.top = headerHeight+"px";
            const topBar = document.querySelector(".header__topbar");
            const headerDiv = header.querySelector(".wrapper > div");

            if (window.innerWidth < 1024) {
                const topBarLinks = topBar.querySelector("nav ul");
                if (topBarLinks) {
                    mainMenu.insertAdjacentElement("beforeend", topBarLinks);
                }
                topBar.style.display = "none";
                topBar.classList.add("moved");
                const searchBtn = headerDiv.querySelector(".search-btn");
                headerDiv.insertAdjacentElement("afterbegin", searchBtn);
            } else {
                if (topBar.classList.contains("moved")) {
                    topBar.style.display = "flex";
                    topBar.classList.remove("moved");
                    const topBarNav = topBar.querySelector("nav");
                    const topBarLinks = mainMenu.lastElementChild;
                    topBarNav.insertAdjacentElement("afterbegin", topBarLinks);
                    const searchBtn = headerDiv.querySelector(".search-btn");
                    headerDiv.insertAdjacentElement("beforeend", searchBtn);    
                }
            }
        }

        mobileMenuSet();

        window.addEventListener("resize", mobileMenuSet);


        /* ALERT BAR */
        const alert_bars = document.querySelectorAll('.alert-bar');
        if (alert_bars !== null) {
            for (let ab_index = 0; ab_index < alert_bars.length; ab_index++) {
                alert_bars[ab_index].querySelector('.close').addEventListener('click', function () {
                    alert_bars[ab_index].classList.add('hidden');
                    alert_bars[ab_index].setAttribute('aria-hidden', true);
                    this.setAttribute('aria-expanded', false);
                })
            }
        }

        /* SKIP LINKS */
        // skip link scroll to section
        const skipToAnchor = (aid) => {
            var aTag = $(aid);
            var focus = true;
            $('html,body').animate({
                scrollTop: aTag.offset().top
            }, 'slow');
            var first_child = $(aTag.children()[0]);
            var tag = first_child.prop('tagName').toLowerCase();

            if (tag !== 'a' && tag !== 'button' && tag !== 'input' && tag !== 'textarea') {
                if (first_child.attr('tabIndex') !== undefined) {
                    first_child.attr('tabIndex', -1).focus();
                    first_child.removeAttr('tabIndex');
                } else {
                    first_child.focus();
                }
            } else {
                first_child.focus();
            }
        }

        // create skip links
        const skipLinks = () => {
            $('section').each(function () {
                const id = $(this).attr('id');
                if (id !== undefined) {
                    // Use the section id to create a label for the items in the skip link list
                    var sectionNameArray = id.split('-');
                    var sectionName = '';
                    for (var i = 0; i < sectionNameArray.length; i++) {
                        var str = sectionNameArray[i];
                        str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                            return letter.toUpperCase();
                        });
                        sectionName += str;
                        if (i < sectionNameArray.length - 1) {
                            sectionName += " ";
                        }
                    }
                    var skiplink = "<li><a href='#" + id + "' class='text-link'>Skip to " + sectionName + "</a></li>";
                    $('.skiplinks ul').append(skiplink);
                }
            });

            const skipLinkContainer = $('.skiplinks'),
                skipLink = $('.skiplinks a');

            skipLink.on('focus', function () {
                skipLinkContainer.addClass('show');
            });

            skipLink.on('blur', function () {
                skipLinkContainer.removeClass('show');
            });

            skipLink.on('click', function (e) {
                e.preventDefault();
                skipToAnchor($(this).attr('href'));
            });
        };

        skipLinks();

        /* LINKS/BUTTONS KEYBOARD FOCUS */
        let buttons = document.querySelectorAll('a, button, input[type="submit"], .swiper-button-prev,' +
            ' .swiper-button-next');
        if (buttons !== null) {
            for (let buttons_index = 0; buttons_index < buttons.length; buttons_index++) {
                const button = buttons[buttons_index];
                button.addEventListener('keyup', function (e) {
                    if (e.keyCode === 9) {
                        button.classList.add('focus');
                    }
                });

                button.addEventListener('blur', function () {
                    button.classList.remove('focus');
                })
            }
        }

        /* LAZY LOAD IMAGES */
        let lazy_load_image = document.querySelectorAll('[data-src]');
        if (lazy_load_image !== null) {
            for (let lazy_index = 0; lazy_index < lazy_load_image.length; lazy_index++) {
                let img = lazy_load_image[lazy_index];
                let imgWaypoint = new Waypoint({
                    element: img,
                    handler: function () {
                        if (img.getAttribute("src") === "") {
                            let src = img.getAttribute("data-src");
                            img.setAttribute('src', src);
                        }
                    },
                    offset: "200%",
                });
            }
        }

        /* EASYDROPDOWN SETUP */
        const dropdowns = document.querySelectorAll('select');
        if (dropdowns !== null) {
            for (let dropdowns_index = 0; dropdowns_index < dropdowns.length; dropdowns_index++) {
                const dropdown = easydropdown(dropdowns[dropdowns_index], {
                    behavior: {
                        useNativeUiOnMobile: false
                    }
                });
            }
        }

        /* GRAVITYFORMS UPLOAD FILE BUTTON */
        const upload_buttons = document.querySelectorAll('.gform_wrapper .upload-button');
        if(upload_buttons !== null) {
            for (let ub_index = 0; ub_index < upload_buttons.length; ub_index++) {
                const upload_button = upload_buttons[ub_index];
                const upload_input = upload_button.parentElement.querySelector('input[type="file"]');
                const file_name_text = upload_button.parentElement.querySelector('.file-name');
                upload_button.addEventListener('click', function (e) {
                    e.preventDefault();
                    upload_input.click();
                });
                upload_input.addEventListener('change', function (e) {
                    let file_name = '';
                    if (this.files && this.files.length > 1) {
                        file_name = ( this.getAttribute('data-multiple-caption') || '' ).replace('{count}', this.files.length);
                    } else if (e.target.value) {
                        file_name = e.target.value.split('\\').pop();
                    }
                    if (file_name) {
                        if (file_name.length > 25) {
                            file_name = file_name.substr(0, 22) + "...";
                        }
                        file_name_text.innerHTML = file_name;
                    } else {
                        file_name_text.innerHTML = "Choose File...";
                    }
                })
            }
        }

        /* BUTTON CTA DATA TEXT FALLBACK */
        let button_ctas = document.querySelectorAll('.button');
        if(button_ctas !== null) {
            for (let bc_index = 0; bc_index < button_ctas.length; bc_index++) {
                const cta = button_ctas[bc_index];
                if(cta.dataset.text === undefined) {
                    cta.dataset.text = cta.innerHTML;
                }
            }
        }


        /* PRIMARY NAV GLOBAL SEARCH DROP DOWN */
        const searchBtn = document.querySelector("header .search-btn");

        if (searchBtn) {

            const toggleSearch = (e) => {
                const btn = e.currentTarget;
                if (btn.classList.contains("active")) {
                    btn.classList.remove("active");
                    btn.setAttribute("aria-expanded", "false");
                    btn.parentElement.nextElementSibling.classList.remove("show");
                    btn.parentElement.nextElementSibling.setAttribute("aria-hidden", "true");
                } else {
                    btn.classList.add("active");
                    btn.setAttribute("aria-expanded", "true");
                    btn.parentElement.nextElementSibling.classList.add("show");
                    btn.parentElement.nextElementSibling.setAttribute("aria-hidden", "false");
                }
            }

            searchBtn.addEventListener("click", toggleSearch)
        }


        // /* TIMELINE SLIDER */
        // const timeline_sliders = document.getElementsByClassName('timeline-swiper');
        // if (timeline_sliders !== null) {
        //     for (let tl_index = 0; tl_index < timeline_sliders.length; tl_index++) {
        //         let swiper = new Swiper(timeline_sliders[tl_index], {
        //             speed: 400,
        //             slidesPerView: 1,
        //             autoHeight: true,
        //             touchRatio: 0,
        //             loop: true,
        //             navigation: {
        //                 nextEl: timeline_sliders[tl_index].querySelectorAll('.swiper-button-next'),
        //                 prevEl: timeline_sliders[tl_index].querySelectorAll('.swiper-button-prev'),
        //             },
        //             pagination: {
        //                 el: timeline_sliders[tl_index].querySelector('.swiper-pagination'),
        //                 type: 'custom',
        //                 renderCustom: function (swiper, current, total) {
        //                     return 'Slide ' + current + ' of ' + total;
        //                 }
        //             },
        //             breakpoints: {
        //                 1024: {
        //                     touchRatio: 1
        //                 }
        //             },
        //             on: {
        //                 slideChange: function () {
        //                     let dates = timeline_sliders[tl_index].querySelectorAll('.swiper-navigation .timeline-dates-container p');
        //                     let active_slide = timeline_sliders[tl_index].querySelectorAll('.swiper-slide')[swiper.activeIndex];
        //                     if (dates !== null) {
        //                         for (let n = 0; n < dates.length; n++) {
        //                             if (n === parseInt(active_slide.dataset.index)) {
        //                                 dates[n].style.display = 'block';
        //                             } else {
        //                                 dates[n].style.display = 'none';
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         });
        //     }
        // }
        //
        // /* TESTIMONIAL SLIDER */
        // const testimonial_sliders = document.getElementsByClassName('testimonial-swiper');
        // if (testimonial_sliders !== null) {
        //     for (let test_index = 0; test_index < testimonial_sliders.length; test_index++) {
        //         let swiper = new Swiper(testimonial_sliders[test_index], {
        //             speed: 400,
        //             slidesPerView: 1,
        //             autoHeight: true,
        //             touchRatio: 0,
        //             loop: true,
        //             navigation: {
        //                 nextEl: testimonial_sliders[test_index].querySelector('.swiper-button-next'),
        //                 prevEl: testimonial_sliders[test_index].querySelector('.swiper-button-prev'),
        //             },
        //             pagination: {
        //                 el: testimonial_sliders[test_index].querySelector('.swiper-pagination'),
        //                 type: 'custom',
        //                 renderCustom: function (swiper, current, total) {
        //                     return current + '/' + total;
        //                 }
        //             },
        //             breakpoints: {
        //                 1024: {
        //                     touchRatio: 1
        //                 }
        //             },
        //         });
        //     }
        // }
        //
        // /* IMAGE SLIDER */
        // const image_sliders = document.getElementsByClassName('image-swiper');
        // if (image_sliders !== null) {
        //     for (let is_index = 0; is_index < image_sliders.length; is_index++) {
        //         let swiper = new Swiper(image_sliders[is_index], {
        //             speed: 400,
        //             slidesPerView: 1,
        //             touchRatio: 0,
        //             loop: true,
        //             navigation: {
        //                 nextEl: '.swiper-button-next',
        //                 prevEl: '.swiper-button-prev',
        //             },
        //             pagination: {
        //                 el: '.swiper-pagination',
        //                 type: 'custom',
        //                 renderCustom: function (swiper, current, total) {
        //                     return current + '/' + total;
        //                 }
        //             },
        //
        //             breakpoints: {
        //                 1024: {
        //                     touchRatio: 1
        //                 }
        //             },
        //         });
        //     }
        // }
        //
        // /* CONTENT SLIDER */
        // const content_sliders = document.getElementsByClassName('content-swiper');
        // if (content_sliders !== null) {
        //     for (let cs_index = 0; cs_index < content_sliders.length; cs_index++) {
        //         let swiper = new Swiper(content_sliders[cs_index], {
        //             speed: 400,
        //             slidesPerView: 3,
        //             spaceBetween: 16,
        //             autoHeight: true,
        //             touchRatio: 0,
        //             loop: true,
        //             navigation: {
        //                 nextEl: content_sliders[cs_index].querySelector('.swiper-button-next'),
        //                 prevEl: content_sliders[cs_index].querySelector('.swiper-button-prev'),
        //             },
        //             pagination: {
        //                 el: '.swiper-pagination',
        //                 type: 'custom',
        //                 renderCustom: function (swiper, current, total) {
        //                     return current + '/' + total;
        //                 }
        //             },
        //             breakpoints: {
        //                 1024: {
        //                     touchRatio: 1
        //                 },
        //                 768: {
        //                     slidesPerView: 2,
        //                     spaceBetween: 16
        //                 },
        //                 681: {
        //                     slidesPerView: 1,
        //                     spaceBetween: 0
        //                 }
        //             }
        //         });
        //     }
        // }
        //
        // /* ACCORDION SECTION */
        // const accordion_items = document.getElementsByClassName('accordion-item');
        // if (accordion_items !== null) {
        //     for (let acc_index = 0; acc_index < accordion_items.length; acc_index++) {
        //         const accordion_item = accordion_items[acc_index];
        //         accordion_item.querySelector('.top button').addEventListener('click', function () {
        //             const accordion_button = this;
        //             accordion_item.classList.toggle('open');
        //             if (accordion_item.classList.contains('open')) {
        //                 accordion_button.setAttribute('aria-expanded', true);
        //                 accordion_item.querySelector('.bottom').setAttribute('aria-hidden', false);
        //             } else {
        //                 accordion_button.setAttribute('aria-expanded', false);
        //                 accordion_item.querySelector('.bottom').setAttribute('aria-hidden', true);
        //             }
        //         })
        //     }
        // }
        //
        // /* TABBED SECTION */
        // const tabbed_buttons = document.getElementsByClassName('tab');
        // const tabbed_contents = document.getElementsByClassName('tab-content');
        // if (tabbed_buttons !== null && tabbed_contents !== null) {
        //     for (let tab_index = 0; tab_index < tabbed_buttons.length; tab_index++) {
        //         const tab_content = tabbed_contents[tab_index];
        //         const tab_button = tabbed_buttons[tab_index];
        //         if (tab_content !== undefined) {
        //             tab_button.addEventListener('click', function () {
        //                 for (let tab_index_2 = 0; tab_index_2 < tabbed_buttons.length; tab_index_2++) {
        //                     tabbed_buttons[tab_index_2].classList.remove('open');
        //                     tabbed_buttons[tab_index_2].setAttribute('aria-expanded', false);
        //                     if (tabbed_contents[tab_index_2] !== undefined) {
        //                         tabbed_contents[tab_index_2].classList.remove('open');
        //                         tabbed_contents[tab_index_2].setAttribute('aria-hidden', true);
        //                     }
        //                 }
        //                 this.classList.add('open');
        //                 this.setAttribute('aria-expanded', true);
        //                 tab_content.classList.add('open');
        //                 tab_content.setAttribute('aria-hidden', false);
        //             });
        //             tab_button.addEventListener('keyup', function (e) {
        //                 if(e.keyCode === 39) {
        //                     if(tab_button.nextSibling.nextSibling !== null) {
        //                         tab_button.nextSibling.nextSibling.focus();
        //                         tab_button.nextSibling.nextSibling.classList.add('focus');
        //                         tab_button.nextSibling.nextSibling.click();
        //                     }
        //                 } else if (e.keyCode === 37) {
        //                     if(tab_button.previousSibling.previousSibling !== null) {
        //                         tab_button.previousSibling.previousSibling.focus();
        //                         tab_button.previousSibling.previousSibling.classList.add('focus');
        //                         tab_button.previousSibling.previousSibling.click();
        //                     }
        //                 }
        //             })
        //         }
        //     }
        // }
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // main set
        const mainSet = () => {
            const header = document.querySelector(".header");
            const headerHeight = header.clientHeight;
            const main = document.querySelector(".main");
            main.style.paddingTop = headerHeight+"px";
        }

        mainSet();

        window.addEventListener("resize", mainSet);

    },
};
