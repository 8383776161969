import Swiper from "swiper/dist/js/swiper.js";

export default {
    init() {
        /* IMAGE SLIDER */
        const image_sliders = document.getElementsByClassName('image-swiper');
        if (image_sliders !== null) {
            for (let is_index = 0; is_index < image_sliders.length; is_index++) {
                let swiper = new Swiper(image_sliders[is_index], {
                    speed: 400,
                    slidesPerView: 1,
                    touchRatio: 0,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'custom',
                        renderCustom: function (swiper, current, total) {
                            return current + '/' + total;
                        }
                    },

                    breakpoints: {
                        1024: {
                            touchRatio: 1
                        }
                    },
                    on: {
                        slideChange: function () {
                            let active_slide = image_sliders[is_index].querySelectorAll('.swiper-slide')[swiper.activeIndex];
                            if(active_slide.querySelector('img').getAttribute('src') === '') {
                                active_slide.querySelector('img').setAttribute('src', active_slide.querySelector('img').getAttribute('data-src'));
                            }
                        }
                    }
                });
            }
        }

        /* ACCORDION SECTION */
        const accordion_items = document.getElementsByClassName('accordion-item');
        if (accordion_items !== null) {
            for (let acc_index = 0; acc_index < accordion_items.length; acc_index++) {
                const accordion_item = accordion_items[acc_index];
                accordion_item.querySelector('.top button').addEventListener('click', function () {
                    const accordion_button = this;
                    accordion_item.classList.toggle('open');
                    if (accordion_item.classList.contains('open')) {
                        accordion_button.setAttribute('aria-expanded', true);
                        accordion_item.querySelector('.bottom').setAttribute('aria-hidden', false);
                    } else {
                        accordion_button.setAttribute('aria-expanded', false);
                        accordion_item.querySelector('.bottom').setAttribute('aria-hidden', true);
                    }
                })
            }
        }
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
