import Swiper from "swiper/dist/js/swiper.js";

export default {
    init() {
        /* TIMELINE SLIDER */
        const timeline_sliders = document.getElementsByClassName('timeline-swiper');
        if (timeline_sliders !== null) {
            for (let tl_index = 0; tl_index < timeline_sliders.length; tl_index++) {
                let swiper = new Swiper(timeline_sliders[tl_index], {
                    speed: 400,
                    slidesPerView: 1,
                    autoHeight: true,
                    touchRatio: 0,
                    loop: true,
                    navigation: {
                        nextEl: timeline_sliders[tl_index].querySelectorAll('.swiper-button-next'),
                        prevEl: timeline_sliders[tl_index].querySelectorAll('.swiper-button-prev'),
                    },
                    pagination: {
                        el: timeline_sliders[tl_index].querySelector('.swiper-pagination'),
                        type: 'custom',
                        renderCustom: function (swiper, current, total) {
                            return 'Slide ' + current + ' of ' + total;
                        }
                    },
                    breakpoints: {
                        1024: {
                            touchRatio: 1
                        }
                    },
                    on: {
                        slideChange: function () {
                            let dates = timeline_sliders[tl_index].querySelectorAll('.swiper-navigation .timeline-dates-container p');
                            let active_slide = timeline_sliders[tl_index].querySelectorAll('.swiper-slide')[swiper.activeIndex];
                            if(active_slide.querySelector('img').getAttribute('src') === '') {
                                active_slide.querySelector('img').setAttribute('src', active_slide.querySelector('img').getAttribute('data-src'));
                            }
                            if (dates !== null) {
                                for (let n = 0; n < dates.length; n++) {
                                    if (n === parseInt(active_slide.dataset.index)) {
                                        dates[n].style.display = 'block';
                                    } else {
                                        dates[n].style.display = 'none';
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }

        /* TESTIMONIAL SLIDER */
        const testimonial_sliders = document.getElementsByClassName('testimonial-swiper');
        if (testimonial_sliders !== null) {
            for (let test_index = 0; test_index < testimonial_sliders.length; test_index++) {
                let swiper = new Swiper(testimonial_sliders[test_index], {
                    speed: 400,
                    slidesPerView: 1,
                    autoHeight: true,
                    touchRatio: 0,
                    loop: true,
                    navigation: {
                        nextEl: testimonial_sliders[test_index].querySelector('.swiper-button-next'),
                        prevEl: testimonial_sliders[test_index].querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: testimonial_sliders[test_index].querySelector('.swiper-pagination'),
                        type: 'custom',
                        renderCustom: function (swiper, current, total) {
                            return current + '/' + total;
                        }
                    },
                    breakpoints: {
                        1024: {
                            touchRatio: 1
                        }
                    },
                });
            }
        }
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
