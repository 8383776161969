import animations from '../components/animations';

export default {
    init () {
        animations();

        const expandBtns = document.querySelectorAll('.landing__types__expand');
        let childList;

        expandBtns.forEach(item => {
            item.addEventListener('click', function (e) {
                childList = item.parentElement.querySelector('.list');
                childList.classList.toggle('active');
                item.classList.toggle('active');
            });
        });

        //get the form from DOM (Document object model)
        var form = document.getElementById('search-form');
        if (form != null) {
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                let request = new XMLHttpRequest();
                request.responseType = 'json';
                request.open('POST', window.sage.ajax_url);
                request.onreadystatechange = function (data) {
                    if (this.readyState == 4 && this.status == 200) {
                        console.log(this.response.html);
                        document.getElementById(
                            'responsehtml'
                        ).innerHTML = this.response.html;
                    }
                };
                var data = new FormData(form);
                request.send(data);
            });

            document
                .getElementById('clear-search')
                .addEventListener('click', function () {
                    form.reset();
                    location.reload();
                });
        }

        const heroBanner = document.querySelector('#intro');
        console.log(heroBanner);

        // function initialLoad(){
        //   // dsktp
        //   heroBanner.classList.add('loaded')
        // };

        function ready (callback) {
            // in case the document is already rendered
            if (document.readyState != 'loading') callback();
            // modern browsers
            else if (document.addEventListener)
                document.addEventListener('DOMContentLoaded', callback);
            // IE <= 8
            else
                document.attachEvent('onreadystatechange', function () {
                    if (document.readyState == 'complete') callback();
                });
        }

        ready(function () {
            // do something
            heroBanner.classList.add('loaded');
        });
    },
    finalize () {
        // JavaScript to be fired on the home page, after the init JS
    },
};
