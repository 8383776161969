export default {
    init() {

		jQuery(document).on('gform_confirmation_loaded', function(event, form_id, current_page){ 
            console.log('form submitted');
			const formTop = document.querySelector(".form-section .centered-headline-container");
            formTop.remove();
        });

		// jQuery(document).on('gform_confirmation_loaded', function(event, form_id, current_page){ 
		// 	const formTop = document.querySelector(".form-section .centered-headline-container");
        //     formTop.remove();
        // });

    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
