import Swiper from "swiper/dist/js/swiper.js";

export default {
    init() {
        /* TESTIMONIAL SLIDER */
        const testimonial_sliders = document.getElementsByClassName('testimonial-swiper');
        if (testimonial_sliders !== null) {
            for (let test_index = 0; test_index < testimonial_sliders.length; test_index++) {
                let swiper = new Swiper(testimonial_sliders[test_index], {
                    speed: 400,
                    slidesPerView: 1,
                    autoHeight: true,
                    touchRatio: 0,
                    loop: true,
                    navigation: {
                        nextEl: testimonial_sliders[test_index].querySelector('.swiper-button-next'),
                        prevEl: testimonial_sliders[test_index].querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: testimonial_sliders[test_index].querySelector('.swiper-pagination'),
                        type: 'custom',
                        renderCustom: function (swiper, current, total) {
                            return current + '/' + total;
                        }
                    },
                    breakpoints: {
                        1024: {
                            touchRatio: 1
                        }
                    },
                });
            }
        }
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
